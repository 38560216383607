<i18n>
{
  "en": {
    "browser_title": "DAILY DOWNLOAD LIMIT"
  },
  "ja": {
    "browser_title": "ダウンロード上限到達のお知らせ"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div class="contents">
      <div v-html="jsonHtml[locale]"></div>
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  components: {
    'vue-headful': vueHeadful,
  },
  data() {
    return {
      dynamicTitle: '',
      jsonHtml: {},
    };
  },
  created() {
    this.refreshHTML();
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  methods: {
    refreshHTML() {
      const bfAPI = new BifrostAPI();
      bfAPI.getStaticPage().then((result) => {
        this.jsonHtml = result.limited;
      });
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>
.contents >>> ul {
  list-style: disc;
}
.contents >>> ol {
  list-style: decimal;
}
.contents >>> li {
  margin-left: 1.4em;
}
</style>
